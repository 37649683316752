import React from 'react'
import { useSelector } from 'react-redux';
import { ALOQA, BIZHAQIMIZDA, BIZNINGKURSLAR, FOTOGALEREYA, NEGABIZ, SAVOLLAR, WELCOME } from '.';


function CRUDPages() {
    const AdminSlise = useSelector((state) => state.AdminReduse.AdminPath)

    return (
        <div className='text-white w-full h-full '>
            {
                AdminSlise === "about-us"&& <BIZHAQIMIZDA /> 
                || AdminSlise === 'our-courses'&& <BIZNINGKURSLAR /> 
                || AdminSlise === 'why-us'&& <NEGABIZ /> 
                || AdminSlise === 'fotogalery'&& <FOTOGALEREYA /> 
                || AdminSlise === 'contact'&& <ALOQA /> 
                || AdminSlise === 'questions'&& <SAVOLLAR /> 
                || AdminSlise === ''&& <WELCOME />
            }
        </div>
    )
}

export default CRUDPages