import React, { useEffect, useState } from 'react'
import { Modal } from 'antd';
import { SideBarJS } from '../utils';
import { Navbar } from "../components/Navbar/PageExport";
import { Footers } from '../components/Footer';
import { useSelector } from 'react-redux';
import ApiRequest, { BASE_URL } from '../../configure/ApiRequestor/ApiRequest';
import { toast } from 'react-toastify';
function Happy() {
  const [visible, setVisible] = useState(false)
  const Moodlight = useSelector((state) => state.sunBTN)
  const [data, setData] = useState([])
  const [img, setImg] = useState(null)
  useEffect(() => {
    getPost()
  }, [])

  function getPost() {
    ApiRequest("/camp", "get")
      .then((res) => {
        setData(res.data);
      }).catch((err) => { toast.error(err, "Galery Serverda xatolik") });
  }


  return (
    <div className={`w-full min-h-max ${!Moodlight.SunVisibility ? "bg-textBlack" : "bg-white"} flex flex-col h-full justify-between bg-red-400`}>
      <SideBarJS />
      <div className='max-w-full min-h-[100px] bg-white'>
        <Navbar />
      </div>


      <div className='min-h-max max-w-[1340px] px-1 flex flex-col gap-4 pt-5'>
        <h1 className={`text-center text-3xl ${!Moodlight.SunVisibility ? "text-white" : "text-textBlack"} font-bold uppercase`}>Yozni ajoyib va zavqli o'tkazing..</h1>
        <div className='flex items-center justify-center flex-wrap gap-4 my-4'>
          {
            data.map((item) => (
              <div key={item.id} className='rounded-3xl minMobil:w-full tablet:max-w-[380px] bg-red-500'>
                <img className='rounded-3xl w-full min-h-[288px]'
                  src={BASE_URL + "api/file/" + item?.photoId}
                  onClick={() => (
                    setVisible(true),
                    setImg(BASE_URL + "api/file/" + item?.photoId)
                  )}
                />
                <Modal
                  centered
                  open={visible}
                  onOk={() => setVisible(false)}
                  onCancel={() => setVisible(false)}
                  width={850}
                  footer={false}
                >
                  <div className='flex minMobil:flex-col tablet:flex-row gap-4 min-h-[400px] items-center justify-stretch'>
                    <img className='minMobil:w-full tablet:w-1/2 h-[400px]' src={img} alt="IMG" />
                    <p className='minMobil:w-full tablet:w-1/2 h-[100%] text-xl font-extralight'>"New star School" xususiy  ta'lim muassasasi - O'zbekistonda ta'lim tizimini rivojlantirish yo'nalishida jadal ish olib bormoqda,unda boshlang'ich maktab ta'limini olish uchun sharoit yaratilgan. Maktab muassasasi O'zbekiston davlat ta'lim standartlariga muvofiq barcha tayanch-o'quv fanlari bo'yicha ta'lim dasturlarini amalga oshiradi. New star School" xususiy  ta'lim muassasasi - O'zbekistonda ta'lim tizimini rivojlantirish yo'nalishida jadal ish olib bormoqda,unda boshlang'ich maktab ta'limini olish uchun sharoit yaratilgan. </p>
                  </div>
                </Modal>
              </div>
            ))
          }

        </div>
      </div>
      <div className='flex items-center justify-center mt-5 bg-textBlack borderWi'>
        <Footers />
      </div>
    </div>
  )
}

export default Happy