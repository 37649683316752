import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ApiRequest, { BASE_URL } from '../../configure/ApiRequestor/ApiRequest'
function CardWhyUse({ img1, img2 }) {
    const Moodlight = useSelector((state) => state.sunBTN)
    const [whyUsData, setWhyUsData] = useState([])

    useEffect(() => {
        getCoursesData()
    }, [])

    function getCoursesData() {
        ApiRequest("/whyUs", "get").then((res) => {
            setWhyUsData(res.data)
        })
    }
    return (
        <div className={`flex items-start justify-center flex-col gap-5 mt-3 ${!Moodlight.SunVisibility ? "text-white" : " text-textBlack"}`}>
            {
                whyUsData.map((res, id) => {
                    console.log(res);
                    return <div className={`w-full min-h-[375px]  flex items-center shadowing justify-between minMobil:flex-col tabletIst:${id % 2 === 0 ? 'flex-row' : 'flex-row-reverse'} gap-5 minMobil:p-0 tablet:p-3 rounded-lg`}>
                        <div className='tabletIst:max-w-[45%] minMobil:w-full overflow-hidden h-[400px] rounded-lg'>
                            <img src={BASE_URL + "api/file/" + res.imageId} alt="img" className='w-[100%] h-[100%] rounded-lg ' />
                        </div>
                        <div className='flex flex-col items-start justify-between minMobil:max-w-full  tabletIst:w-1/2 min-h-full minMobil:p-2'>
                            <h1 className=' tablet:text-4xl minMobil:text-2xl font-bold'>{res.title}</h1> <br /> <br />
                            <p className=' tablet:text-2xl minMobil:text-lg font-semibold '>{res.description}</p>
                        </div>
                    </div>
                })
            }
        </div>

    )
}

export default CardWhyUse