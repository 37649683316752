import React, {useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ApiRequest from '../../../configure/ApiRequestor/ApiRequest'
function Contact() {
  const [Name, setName] = useState('')
  const [Adress, setAdress] = useState('')
  const [Age, setAge] = useState('')
  const [Phone, setPhone] = useState('')
  function AddContactSubmit() {
    if (Name !== '' && Adress !== '' && Age !== '' && Phone !== '') {
      ApiRequest("/contact", "post",{
        name: Name,
        address: Adress,
        age: Age,
        phone: Phone
      }).then(() => {
        toast.success("Ariza muvoffaqiyatli qoldirildi siz bilan tez orada bog'lanamiz")
        setName('')
        setAdress('')
        setAge('')
        setPhone('')
      }).catch((error) => {
        toast.error(error)
      })
    }
    else {
      toast.error(`Hurmatli ${Name !== '' ? Name.toUpperCase() : 'mijoz'} Iltimos barcha qatorlarni to'ldiring `)
    }
  }
  return (
    <div id='contact' className='min-h-[570px] flex justify-center items-start flex-col '>
      <div className='w-full bg-green min-h-[570px] tablet:rounded-[40px] minMobil:rounded-md tablet:px-[30px] minMobil:px-2 minMobil:py-7 tablet:py-5 flex flex-col items-center justify-start gap-5'>
        <h1 className='w-full tablet:text-start minMobil:text-center tablet:text-4xl minMobil:text-2xl font-semibold text-white uppercase mt-3'>Qabulga ro’yxatdan o’ting:</h1>
        <div className='w-full flex items-center justify-center flex-wrap gap-5 align-baseline'>
          <div className='w-[100%] flex items-center justify-between minMobil:flex-col tablet:flex-row gap-5 align-baseline'>
            <div className='tablet:w-1/2 minMobil:w-full flex flex-col items-start justify-center gap-3 '>
              <p className='tablet:text-3xl minMobil:text-2xl text-textBlack font-semibold leading-7 '>Ismingiz:</p>
              <input value={Name} onChange={(e) => (setName(e.target.value))} className='form-control form-select-lg mb-3 tablet:max-w-[100%] minMobil:w-full' type="text" placeholder='Ismingizni kiriting' />
            </div>
            <div className='tablet:w-1/2 minMobil:w-full flex flex-col items-start justify-center gap-3'>
              <p className='tablet:text-3xl minMobil:text-2xl text-textBlack font-semibold leading-7'>Yashash manzili:</p>
              <select value={Adress} onChange={(e) => (setAdress(e.target.value))} className="form-select form-select-lg mb-3 tablet:max-w-[100%] minMobil:w-full" aria-label="Large select example">
                <option value='' selected disabled hidden>Qaysi tumanda istiqomat qilasiz?</option>
                <option value="Buxoro shaxri">Buxoro shaxri</option>
                <option value="Buxoro tumani">Buxoro tumani</option>
                <option value="Kogon shaxri">Kogon shaxri</option>
                <option value="G'ijduvon tumani">G'ijduvon tumani</option>
                <option value="Jondor tumani">Jondor tumani</option>
                <option value="Olot tumani">Olot tumani</option>
                <option value="Peshku tumani">Peshku tumani</option>
                <option value="Qorako'l tumani">Qorako'l tumani</option>
                <option value="Qorovulbozor tumani">Qorovulbozor tumani</option>
                <option value="Romitan tumani">Romitan tumani</option>
                <option value="Shofirkon tumani">Shofirkon tumani</option>
                <option value="Vobkent tumani">Vobkent tumani</option>
              </select>
            </div>
          </div>
          <div className='w-full flex items-center justify-between minMobil:flex-col tablet:flex-row gap-5 align-baseline'>
            <div className='tablet:w-1/2 minMobil:w-full flex flex-col items-start justify-center gap-3'>
              <p className='tablet:text-3xl minMobil:text-2xl text-textBlack font-semibold leading-7 '>Hozir farzandingiz necha yoshda?</p>
              <input value={Age} onChange={(e) => (setAge(e.target.value))} className='form-control form-select-lg mb-3 tablet:max-w-[100%] minMobil:w-full' type="number" placeholder='Example: 15' />
            </div>
            <div className='tablet:w-1/2 minMobil:w-full flex flex-col items-start justify-center gap-3'>
              <p className='tablet:text-3xl minMobil:text-2xl text-textBlack font-semibold leading-7 '>Telefon raqamingiz:</p>
              <input value={Phone} onChange={(e) => (setPhone(e.target.value))} className='form-control form-select-lg mb-3 tablet:max-w-[100%] minMobil:w-full' type="tel" placeholder='998 99 000 00 00' />
            </div>
          </div>
          <button onClick={AddContactSubmit} className='btn-contact'>Ariza yuborish</button>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Contact