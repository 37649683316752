import React from 'react'
import { SideBarJS } from '../utils'
import { useSelector } from 'react-redux'
import { Footers } from '../components/Footer'
import { Navbar } from '../components/Navbar/PageExport'
import { Happy } from './index'

function Galery() {
    const Moodlight = useSelector((state) => state.sunBTN)
    return (
        <div className={`flex flex-col justify-between min-h-screen ${!Moodlight.SunVisibility ? "bg-textBlack" : "bg-white"}`}>
            <SideBarJS />
            <Navbar />
            <div className='flex items-center justify-center'>
                <div className='w-[1340px] px-1 flex flex-col gap-4'>
                    <div className='flex items-center justify-start gap-3 mt-4'>
                        <div className='w-14 h-1 bg-green minMobil:hidden tablet:block'></div>
                        <h1 className={`text-textBlack tablet:text-4xl minMobil:text-2xl leading-2 font-bold uppercase minMobil:text-center tablet:text-start w-full ${!Moodlight.SunVisibility ? "text-white" : " text-textBlack"}`}>Bizning <span className="text-green">galereya</span></h1>
                    </div>
                    <h1 className={`text-center text-3xl ${!Moodlight.SunVisibility ? "text-white" : "text-textBlack"} font-bold uppercase`}>ko'ngilochar dasturlar</h1>
                    <div className='w-full'>
                        <Happy/>
                    </div>
                </div>
            </div>
            <div className='w-full bg-textBlack flex justify-center borderWi '>
                <Footers />
            </div>
        </div>
    )
}

export default Galery