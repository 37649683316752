import { facebookIcon, instaIcon, locationIcon, phoneIcon, telegramIcon } from "../../../icons/icons.";

export default function TopBar() {
  return (
    <div className=' max-w-full h-12 bg-green flex items-center justify-center overflow-hidden py-4'>
      <div className="w-[1340px] minMobil:px-4 tablet:mx-0 min-h-8 flex items-center justify-between">
        <div className="flex items-center justify-center gap-5">
          <a href="https://maps.app.goo.gl/nMXYJPzteWqmZKTQ9" className="flex items-center justify-center gap-2">
            <img src={locationIcon} alt="Location-icons" />
            <p className="text-white tablet:text-[16px] minMobil:text-[14px] flex items-center justify-center">Buxoro shahar <span className="minMobil:hidden tablet:block">, Xo’ja Alovuddin ko’cha 81-uy</span></p>
          </a>
          <a href="tel:+998 55 500 77 74" className="minMobil:hidden mobil:flex items-center justify-center gap-2">
            <img src={phoneIcon} alt="phoneIcon" />
            <p className="text-white tablet:text-[16px] minMobil:text-[14px] ">+998 55 500 77 74</p>
          </a>
        </div>
        <div className="flex items-center justify-center gap-3">
          <a className="minMobil:w-5 tablet:w-full minMobil:h-5 tablet:h-full" href="https://www.instagram.com/newstar_school"><img src={instaIcon} alt="instaIcon" /></a>
          <a className="minMobil:w-5 tablet:w-full minMobil:h-5 tablet:h-full" href="https://t.me/new_starschool"><img src={telegramIcon} alt="telegramIcon" /></a>
          <a className="minMobil:w-5 tablet:w-full minMobil:h-5 tablet:h-full" href="https://www.facebook.com/profile.php?id=61556948009833"><img src={facebookIcon} alt="facebookIcon" /></a>
        </div>
      </div>
    </div>
  )
}
