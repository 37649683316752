import React from 'react'
import { Link } from 'react-router-dom'

function FaceHome() {
    return (

        <div className=' flex items-center justify-center homeBlack '>
            <div className=' flex flex-col items-center justify-center gap-8'>
                <h1 className='tablet:text-[60px] minMobil:text-[32px] text-green font-bold text-center '>Farzandingiz iste’dodini kashf qilamiz</h1>
                <p className='text-white  text-center tablet:max-w-[950px]  tablet:text-2xl minMobil:text-[16px'>Biz har bir bola o’qishga, ilmiy, ijodiy va sport g’alabalariga erishishga, muloqot qilishga, do’stlashishga va shunchaki yashashga qiziqadigan maktab yaratmoqdamiz.</p>
                <Link to={'/contact'} className='px-5 py-3 bg-green  rounded-lg btn-hover'>Qabul - {new Date().getFullYear()} </Link>
            </div>
        </div>

    )
}

export default FaceHome