import { createSlice } from "@reduxjs/toolkit";

export const visibilityBtn = createSlice({
    name: "visibilityBtn",
    initialState: {
        visibility: false
    },
    reducers: {
        trueVisibility: (state) => {
            if (state.visibility === true) {
                state.visibility = false
            }
            else {
                state.visibility = true
            }

        }
    }
})
export const { trueVisibility } = visibilityBtn.actions;

export default visibilityBtn.reducer