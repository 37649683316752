import React, { useEffect, useState } from 'react'
import Question from './Question'
import { useSelector } from 'react-redux'
import ApiRequest from '../../../configure/ApiRequestor/ApiRequest'
function AnswersToQuestions() {
  const Moodlight = useSelector((state) => state.sunBTN)
  const [db, setDB] = useState([])

  useEffect(() => {
    ApiRequest("/landQuestion", "get").then((res) => {
      setDB(res.data);
    });
  }, [])

  return (
    <div className='w-[1340px] flex items-start justify-center flex-col'>
      <div className='flex items-center justify-center  gap-3 '>
        <div className='w-14 h-1 bg-green minMobil:hidden tablet:block'></div>
        <h1 className={`${!Moodlight.SunVisibility ? "text-white" : " text-textBlack"} tablet:text-4xl minMobil:text-2xl leading-2 font-bold my-5 uppercase tablet:text-start minMobil:text-center w-full `}>Ko’p so’raladigan <span className='text-green'>savollar</span></h1>
      </div>
      <div className='flex items-stretch w-full justify-center minMobil:px-2 tablet:px-1 '>
        <Question />
      </div>
    </div >
  )
}

export default AnswersToQuestions