import React, { useEffect } from 'react'
import { navbar } from '../../export'

function Right() {
    return (
        <div className='minMobil:mt-5 tablet:mt-0'>
            <ul className='flex flex-col items-startenter justify-center gap-2 '>{
                navbar.map((item, i) => {
                    return (
                        <li key={i + 1} className='tablet:text-2xl minMobil:text-xl font-mono uppercase'>
                            <a href={item?.link}>{item?.title}</a>
                        </li>
                    )
                })
            }</ul>
        </div>
    )
}

export default Right