import { createSlice } from "@reduxjs/toolkit";

export const sunBTN = createSlice({
    name: 'sunBTN',
    initialState: {
        SunVisibility: localStorage.getItem('sunBTN'),
    },
    reducers: {
        trueVisibilityMood: (state) => {
            if (state.SunVisibility  && localStorage.getItem('sunBTN')) {
                state.SunVisibility = false
                localStorage.setItem('sunBTN', false)
            }
            else {
                state.SunVisibility = true
                localStorage.setItem('sunBTN', true)
            }
        }
    }
})
export const { trueVisibilityMood } = sunBTN.actions;
export default sunBTN.reducer