import React from 'react'
import { FooterCenter, FooterLeft, FooterRight } from '.'

function Footer() {
    return (
        <div className='flex items-center tablet:justify-between minMobil:justify-start  tablet:w-[1340px] minMobil:w-full min-h-96 minMobil:p-2 tablet:py-3 flex-wrap text-white tablet:gap-5 minMobil:gap-3 '>
            <div>
                <FooterLeft />
            </div>
            <div className='max-w-[420px]'>
                <FooterCenter />
            </div>
            <div className='minMobil:hidden tablet:block'>
                <FooterRight />
            </div>

        </div>
    )
}

export default Footer