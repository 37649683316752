import React from 'react'
import { Navbar } from '../components/Navbar/PageExport'
import { Footers } from '../components/Footer/index'
import { SideBarJS } from '../utils/index'
import { useSelector } from 'react-redux'
import { ContactHome } from '../components/Section'
import { MAP } from './collme'


function CallMe() {
    const Moodlight = useSelector((state) => state.sunBTN)
    return (
        <div className={`flex flex-col justify-between min-h-screen ${!Moodlight.SunVisibility ? "bg-textBlack" : "bg-white"}`}>
            <SideBarJS />
            <Navbar />
            <div className='flex items-center justify-center my-5 '>
                <div className='max-w-[1340px] flex flex-col gap-5'>
                    <div className='flex items-center justify-center w-full gap-3 '>
                        <div className='w-14 h-1 bg-green minMobil:hidden tablet:block'></div>
                        <h1 className={`w-[100%] text-textBlack tablet:text-4xl minMobil:text-2xl leading-2 font-bold uppercase tablet:text-start minMobil:text-center ${!Moodlight.SunVisibility ? "text-white" : " text-textBlack"}`}>Biz bilan <span className='text-green'>Bog’lanish</span></h1>
                    </div>
                    <MAP />
                    <ContactHome />
                </div>
            </div>
            <div className='w-full bg-textBlack flex justify-center borderWi '>
                <Footers />
            </div>
        </div>
    )
}

export default CallMe