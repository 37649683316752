import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { leftStrelka } from '../../icons/icons.'
import { COURSEIMG, rusCardImg } from '../../img/img'


function NewCardCoursePage({ img, icon, url, title, discription }) {
    const Moodlight = useSelector((state) => state.sunBTN)
    return (
        <div className={`max-w-96 min-h-[420px] ${!Moodlight.SunVisibility ? "bg-textBlack" : "bg-white"} borderGreen card-border-radius-2 rounded-3xl overflow-hidden`}>
            <div className='w-full h-[40%] bg-red-300 bg-cover rounded-t-xl overflow-hidden flex items-center justify-center'>
                <img src={img !== null ? img : COURSEIMG} alt="img" className='w-full h-full ' />
            </div>
            <div className='w-full h-[60%] px-4 py-3 flex flex-col items-start justify-start gap-3'>
                <div className='flex items-center justify-between w-full'>
                    <h1 className='text-green font-bold text-2xl'>{title}</h1>
                    <img width={45} src={icon} alt="icon" />
                </div>
                <p className={`${!Moodlight.SunVisibility ? "text-white" : "text-textBlack"} text-justify`}>{discription}</p>
                <Link to={url} className='text-green durationText flex items-center justify-start gap-2'>Kurasga yozilish <img src={leftStrelka} alt="" /> </Link>
            </div>
        </div>
    )
}

export default NewCardCoursePage