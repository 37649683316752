import { createSlice } from "@reduxjs/toolkit";

export const AdminSlice = createSlice({
    name: 'AdminReduse',
    initialState: {
        AdminPath: ""
    },
    reducers: {
        AdminCRUDPageChange: (state, action) => {
            state.AdminPath = action.payload
        }
    }
})

export const { AdminCRUDPageChange } = AdminSlice.actions

export default AdminSlice.reducer