import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import ApiRequest, { BASE_URL } from '../../../../configure/ApiRequestor/ApiRequest';

function Cump() {
    const [img, setImg] = useState('');
    const [description, setDescription] = useState('');
    const [data, setData] = useState([]);
    const [EditId, setEditId] = useState('');

    useEffect(() => {
        getPost();
    }, []);

    function deplayImg(e) {
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("prefix", "/images/camp");
        ApiRequest("/file", "post", formData).then((res) => {
            setImg(res.data);
            toast.info(res.data);
        }).catch((err) => { toast.error(err); });
    }

    function getPost() {
        ApiRequest("/camp", "get").then((res) => {
            setData(res.data);
        }).catch((err) => { toast.error(err); });
    }

    function PostComp() {
        if (EditId === '') {
            ApiRequest("/camp", "post", {
                photoId: img,
                description: description
            }).then((res) => {
                toast.success("Successfully");
                getPost();
                setDescription('');
                setImg('');
                setEditId('');
            }).catch((err) => { toast.error(err); });
        } else {
            ApiRequest("/camp/" + EditId, "put", {
                photoId: img,
                description: description
            }).then((res) => {
                toast.success("Edit Successfully");
                getPost();
                setDescription('');
                setImg('');
                setEditId('');
            }).catch((err) => { toast.error(err); });
        }
    }

    function DeleteItem(i) {
        ApiRequest("/camp/" + i, "delete").then((res) => {
            toast.success("Successfully");
            getPost();
        }).catch((err) => { toast.error(err); });
    }

    function EditItem(i) {
        setImg(i.img);
        setDescription(i.description);
        setEditId(i.id);
        getPost();
    }

    return (
        <div className='flex flex-col gap-5'>
            <div className='flex flex-col gap-3'>
                <input onChange={deplayImg} type="file" />
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    className='form-control w-50 h-40 form-control placeholder:uppercase placeholder:font-mono'
                    placeholder='Kurs haqida batafsil izoh qoldiring kamida 100ta belgi'>
                </textarea>
                <button onClick={PostComp} className='py-2 px-3 bg-green w-1/4 rounded-lg'>Yuklash</button>
            </div>
            <table className='table table-dark'>
                <thead>
                    <tr>
                        <th>id</th>
                        <th>img</th>
                        <th>dicription</th>
                        <th>Delete</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length === 0 ? <h1 className='text-3xl'>loader.....</h1> :
                        data.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td><img width={100} src={`${BASE_URL}api/file/${item.photoId}`} alt="img" /></td>
                                <td>{item.description}</td>
                                <td><button onClick={() => DeleteItem(item.id)} className='btn btn-danger'>Delete</button></td>
                                <td><button onClick={() => EditItem(item)} className='btn btn-warning'>Edit</button></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <ToastContainer />
        </div>
    );
}

export default Cump;
