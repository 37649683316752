import React from 'react'
import { error404 } from '../../icons/icons.'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
function NoteFoundPage() {
    const Moodlight = useSelector((state) => state.sunBTN)
    return (
        <div className={`flex items-center justify-center flex-col gap-3 my-5 ${!Moodlight.SunVisibility ? "bg-textBlack" : "bg-white"}`}>
            <img src={error404} alt="" />
            <h1 className={`tablet:text-5xl minMobil:text-3xl font-bold font-serif ${!Moodlight.SunVisibility ? "text-white" : "text-textBlack"}`}>
                Sahifa topilmadi :(
            </h1>
            <Link to='/' className='text-white font-semibold bg-green py-4 px-12 rounded-2xl '>Bosh sahifaga qaytish</Link>
        </div>
    )
}

export default NoteFoundPage