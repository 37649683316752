import React, { useEffect, useState } from 'react'
import { Navbar } from '../components/Navbar/PageExport'
import Footer from '../components/Footer/Footer'
import { SideBarJS } from '../utils'
import { AnswersToQuestions, FaceAboutMe, WhyUs } from '../components/Section'
import { useSelector } from 'react-redux'

function About() {
    const Moodlight = useSelector((state) => state.sunBTN)

    const [loader, setLoader] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoader(false)
        }, 4.5 * 1000)
    }, [loader])

    return (
        <>
            {
                loader ? <div className={`w-full min-h-screen flex items-center justify-center const ${!Moodlight.SunVisibility ? "bg-textBlack" : " bg-white"}`}>
                    <span className="loader w-[300px] "></span>
                </div>
                    :
                    <div className={`w-full flex flex-col  justify-between ${!Moodlight.SunVisibility ? "bg-textBlack" : "bg-white"}`}>
                        <div>
                            <SideBarJS />
                            <Navbar />
                        </div>
                        <div className='flex items-center justify-center flex-col my-5'>
                            <FaceAboutMe />
                        </div>
                        <div id='scale-animation-id' className='w-full  flex items-center justify-center my-5'>
                            <WhyUs />
                        </div>
                        <div id='scale-animation-id' className='flex items-center justify-center my-5'>
                            <AnswersToQuestions />
                        </div>

                        <div className='flex items-center justify-center mt-5 bg-textBlack borderWi'>
                            <Footer />
                        </div>
                    </div>

            }


        </>
    )
}

export default About