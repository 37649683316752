import React, { useEffect, useState } from 'react'
import ApiRequest from '../../../configure/ApiRequestor/ApiRequest'
function Question() {
    const [db, setDB] = useState([])
    useEffect(() => {
        getItem()
    }, [])
    function getItem() {
        ApiRequest("/landQuestion", "get").then((res) => {
            setDB(res.data)
        })
    }
    function changeItem(item) {
        ApiRequest("/landQuestion/" + item.id, "put", {
            title: item.title,
            description: item.description,
            isActive:  item.isActive ? false : true
        }).then(() => {
            getItem()
        }).catch(() => {
    })
    }

    return (
        <div className='w-full flex items-start justify-between flex-wrap gap-5'>
            {
                db.map((item) => {
                    return <button key={item?.id} className={` ${item?.active ? "scale-up-ver-top" : ""} minMobil:w-[100%] tablet:max-w-[45%] min-h-max rounded-xl overflow-hidden bg-gray  py-2 px-3 `}>
                        <div onClick={() => (changeItem(item))} className='flex items-center justify-between w-full align-top text-textBlack font-semibold cursor-pointer'>
                            <p className='font-bold tablet:text-2xl minMobil:text-xl minMobil:leading-8 minMobil:mb-2 tablet:mb-0 tablet:leading-[48px]'>{item?.title}</p>
                            <span className={`text-[22px] font-[900] ${item?.isActive ? 'rotate-center' : 'rotate-center-360'} `}>{item?.isActive ? "-" : "+"}</span>
                        </div>
                        <p className={`${item?.isActive ? 'slide-top ' : 'hidden'} cursor-text text-start w-full`}>{item.description}</p>
                    </button>
                })
            }

        </div>
    )
}

export default Question