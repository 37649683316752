import React, { useState } from 'react'
import './index.css'
import { login } from '../export'
import { useNavigate } from 'react-router-dom'
function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()
    function Submit() {
        if (email === login.email && password === login.password) {
            localStorage.setItem('token', login.token)
            navigate(`/webadmin/${login.token}`)
        }
        else {
            alert('Email yoki parol noto\'g\'ri')
        }
    }
    return (
        <div className='body-login'>
            <div className="login">
                <div className="login-triangle"></div>
                <h2 className="login-header">Kirish</h2>
                <div className="login-container">
                    <p><input value={email} onChange={(e) => (setEmail(e.target.value))} type="email" placeholder="Email or phone number" /></p>
                    <p><input value={password} onChange={(e) => (setPassword(e.target.value))} type="password" placeholder="Password" /></p>
                    <div className='flex items-center justify-center'>
                        <button onClick={Submit}>Log in</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login