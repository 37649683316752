import React, { useEffect, useState } from 'react'
import ApiRequest, { BASE_URL } from '../../../../configure/ApiRequestor/ApiRequest'
import { ToastContainer, toast } from 'react-toastify';
function Galery() {
    const [img, setImg] = useState('')
    const [description, setDescription] = useState('')
    const [data, setData] = useState([])
    const [EditId, setEditId] = useState('')


    useEffect(() => {
        getPost()
    }, [])

    function deplayImg(e) {
        let formData = new FormData
        formData.append("file", e.target.files[0])
        formData.append("prefix", "/images/whyUsImages")
        ApiRequest("/file", "post", formData).then((res) => {
            setImg(res.data)
            toast.info(res.data)
        }).catch((err) => { toast.error(err) })

    }

    // photoGallery
    function getPost() {
        ApiRequest("/photoGallery", "get")
            .then((res) => {
                setData(res.data);
            }).catch((err) => { toast.error(err, "Galery Serverda xatolik") });
    }

    function PostGalery() {
        if (EditId === '') {
            ApiRequest("/photoGallery", "post", {
                photoId: img,
                description: description
            }).then((res) => {
                getPost()
                setDescription('')
                setImg('')
                setEditId('')
                toast.success("Successfully")
            }).catch((err) => {
                toast.error(err, "Serverda xatolik")
            });
        }
        else {
            ApiRequest("/photoGallery/" + EditId, "put", {
                photoId: img,
                description: description
            }).then(() => {
                toast.success("Edit Successfully")
                getPost()
                setDescription('')
                setImg('')
                setEditId('')
            }).catch((err) => { toast.error(err) })

        }
    }

    function DeleteItem(i) {
        ApiRequest("/photoGallery/" + i, "delete").then(() => {
            toast.success("Successfully")
            getPost()
        }).catch((err) => { toast.error(err) });
    }
    function EditItem(i) {
        setImg(i.img)
        setDescription(i.description)
        setEditId(i.id)
        getPost()
    }

    return (
        <div className='flex flex-col gap-5'>
            <div className='flex flex-col gap-3'>
                <input onChange={(e) => (deplayImg(e))} type="file" />
                <textarea
                    value={description}
                    onChange={(e) => (setDescription(e.target.value))}
                    required
                    className='form-control w-50 h-40 form-control placeholder:uppercase placeholder:font-mono '
                    placeholder='Kurs haqida batafsil izoh qoldiring kamida 100ta belgi'>
                </textarea>
                <button onClick={PostGalery} disabled={!img && description.length < 50 ? true : false} className='py-2 px-3 bg-green w-1/4 rounded-lg'>Yuklash</button>
            </div>
            <table className='table table-dark'>
                <thead>
                    <tr>
                        <th>id</th>
                        <th>img</th>
                        <th>dicription</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.length === 0 ? <h1 className='text-3xl '>Loader....</h1> :
                            data.map((i) => {
                                return (
                                    <tr key={i.id}>
                                        <td>{i?.id}</td>
                                        <td><img width={100} src={BASE_URL + "api/file/" + i?.photoId} alt="" /></td>
                                        <td>{i?.description}</td>
                                        <td>
                                            <button onClick={() => EditItem(i)} className='btn btn-warning'>Edit</button>
                                        </td>
                                        <td>
                                            <button onClick={() => DeleteItem(i.id)} className='btn btn-danger'>Delete</button>
                                        </td>
                                    </tr>
                                )
                            })
                    }
                </tbody>
            </table>
            <ToastContainer />
        </div>
    )
}

export default Galery