import { React, useEffect, useState } from 'react'
import Navbar from './Navbar/Navbar'
import { AnswersToQuestions, ContactHome, FaceAboutMe, FaceHome, Galery, OurCourses, WhyUs } from './Section';
import { SideBarJS } from '../utils';
import { Footers } from './Footer';
import { useSelector } from 'react-redux';


export default function Home() {
    const Moodlight = useSelector((state) => state.sunBTN)

    const [loader, setLoader] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setLoader(false)
        }, 5 * 1000)
    }, [loader])

    return (

        <div className={`${!Moodlight.SunVisibility ? "bg-textBlack body" : "bg-white "}`}>
            {loader ?
                <div className={`w-full min-h-screen flex items-center justify-center ${!Moodlight.SunVisibility ? "bg-textBlack" : "bg-white"}`}>
                    <span className="loader w-[300px] "></span>
                </div>
                :
                <div className={`w-full min-h-max ${!Moodlight.SunVisibility ? "bg-textBlack" : "bg-white"}`}>
                    <SideBarJS />
                    <div className='max-w-full min-h-[100px] bg-white'>
                        <Navbar />
                    </div>
                    <div className='w-full flex items-center justify-center '>
                        <div className='homeFace flex items-center justify-center w-full'>
                            <FaceHome />
                        </div>
                    </div>
                    <div id='scale-animation-id' className='flex items-center justify-center flex-col my-5'>
                        <FaceAboutMe />
                    </div>
                    <div id='scale-animation-id' className='w-full flex items-center justify-center my-5'>
                        <OurCourses />
                    </div>
                    <div id='scale-animation-id' className='w-full  flex items-center justify-center my-5'>
                        <WhyUs />
                    </div>
                    <div id='scale-animation-id' className='flex items-center justify-center my-5'>
                        <Galery />
                    </div>
                    <div id='scale-animation-id' className='flex items-center justify-center my-5'>
                        <div className='max-w-[1340px]'>
                            <ContactHome />
                        </div>
                    </div>
                    <div id='scale-animation-id' className='flex items-center justify-center my-5'>
                        <AnswersToQuestions />
                    </div>
                    <div className='flex items-center justify-center mt-5 bg-textBlack borderWi'>
                        <Footers />
                    </div>
                </div>
            }
        </div>



    )
}
