import React, { useEffect } from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import ApiRequest from "../../../../configure/ApiRequestor/ApiRequest";
import "react-toastify/dist/ReactToastify.css";
function BizHaqimizda() {
  const [TextAreaTablet, setTextAreaTablet] = useState("");
  const [TextAreaMobile, setTextAreaMobile] = useState("");
  const [inp, setInp] = useState("");
  const [getPosts, setGetPosts] = useState([]);
  const [EditText, setEditText] = useState("");

  useEffect(() => {
    GETDB();
  }, []);

  function GETDB() {
    ApiRequest("/aboutUs", "get")
      .then((response) => {
        setGetPosts(response.data);
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  function AboutUsBTN() {
    if (getPosts.length === 0 || EditText !== "") {
      if (EditText === "") {
        if (true) {
          ApiRequest("/aboutUs", "post", {
            textTablet: TextAreaTablet,
            textMobile: TextAreaMobile,
            youtubeUrl: inp,
          })
            .then((res) => {
              toast.success("success");
              setTextAreaTablet("");
              setTextAreaMobile("");
              setInp("");
              GETDB();
            })
            .catch((error) => {
              toast.error(error);
            });
        } else {
          toast.error("error");
        }
      } else {
        ApiRequest("/aboutUs/" + EditText.id, "put", {
          textTablet: TextAreaTablet,
          textMobile: TextAreaMobile,
          youtubeUrl: inp,
        }).then((res) => {
          toast.success("success edit");
          setTextAreaTablet("");
          setTextAreaMobile("");
          setEditText("");
          setInp("");
          GETDB();
        });
      }
    } else {
      toast.info("2-ma'lumot ortiqcha faqat tahrirlash mumkin");
    }
  }
  function DeleteInfo(info) {
    ApiRequest("/aboutUs/" + info.id, "delete")
      .then((res) => {
        toast.success("success delete info");
        GETDB();
      })
      .catch((error) => {
        toast.error(error);
      });
  }
  function EditInfo(getPosts) {
    setEditText(getPosts);
    setTextAreaMobile(getPosts.textTablet);
    setTextAreaTablet(getPosts.textMobile);
    setInp(getPosts.youtubeUrl);
  }
  return (
    <div className="py-3 flex flex-col gap-5">
      <h1 className="text-4xl font-mono text-center">Biz haqimizda</h1>
      <div className="flex items-end justify-start flex-col gap-3 w-1/2 ">
        <textarea
          value={TextAreaTablet}
          onChange={(e) => setTextAreaTablet(e.target.value)}
          className="w-full h-40 form-control placeholder:uppercase placeholder:font-mono"
          placeholder="Biz Haqimizda izoh qoldiring Text Tablet uchun"
        ></textarea>
        <textarea
          value={TextAreaMobile}
          onChange={(e) => setTextAreaMobile(e.target.value)}
          className="w-full h-40 form-control placeholder:uppercase placeholder:font-mono"
          placeholder="Biz Haqimizda izoh qoldiring Text Mobile uchun"
        ></textarea>
        <input
          value={inp}
          onChange={(e) => setInp(e.target.value)}
          className="w-full form-control placeholder:uppercase placeholder:font-mono"
          type="text"
          placeholder="Biz haqimizda video link YouTube-dan"
        />
        <button
          disabled={TextAreaMobile.length >= 50 && TextAreaTablet.length >= 50 && inp !== '' ? false : true}
          onClick={() => AboutUsBTN()} className="btn btn-success">
          Yakunlash
        </button>
      </div>
      <div className="flex flex-col gap-5 overflow-auto h-96">
        {getPosts.map((item, index) => {
          return (
            <div
              key={index}
              className="flex flex-col  items-center justify-center gap-3"
            >
              <div className="flex items-center justify-between gap-4">
                <span className="text-xl font-mono max-w-1/2 bg-red-400">
                  {item?.textTablet}
                </span>
                <span className="text-xl font-mono max-w-1/2  bg-orange-400">
                  {item?.textMobile}
                </span>
              </div>
              <iframe
                src={item.youtubeUrl}
                className="text-xl w-1/3 h-56 font-mono"
                frameborder="0"
                allow="accelerometer; 
               autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                referrerpolicy="strict-origin-when-cross-origin"
              />
              <div className="flex items-center justify-center gap-5">
                <button
                  onClick={() => DeleteInfo(item)}
                  className="btn btn-danger"
                >
                  Delet
                </button>
                <button
                  onClick={() => EditInfo(item)}
                  className="btn btn-warning"
                >
                  Edit
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <ToastContainer />
    </div>
  );
}

export default BizHaqimizda;
