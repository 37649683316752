import React from 'react'
import { FooterLogo, facebookIcon, instaIcon, telegramIcon } from '../../../icons/icons.'

function Left() {
  return (
    <div className='w-[350px] p-1 flex flex-col items-start justify-center tablet:gap-6 minMobil:gap-7'>
      <div className='flex items-center gap-3'>
        <img src={FooterLogo} alt="FooterLogo" />
        <div className='flex flex-col '>
          <h1 className='tablet:text-2xl minMobil:text-xl font-bold uppercase'>New Star <span className='text-green lowercase'>school</span></h1>
          <p className='tablet:w-[330px] minMobil:w-full text-white tablet:text-sm minMobil:text-[12px] leading-5'>“New star School" xususiy  ta'lim muassasasi - O'zbekistonda ta'lim tizimini rivojlantirish yo'nalishida jadal ish olib bormoqda.</p>
        </div>
      </div>

      <div className="flex items-center justify-start gap-4 ">
        <a className='w-7' href="https://www.instagram.com/newstar_school"><img src={instaIcon} alt="instaIcon" /></a>
        <a className='w-7' href="https://t.me/new_starschool"><img src={telegramIcon} alt="telegramIcon" /></a>
        <a className='w-7' href="https://www.facebook.com/profile.php?id=61556948009833"><img src={facebookIcon} alt="facebookIcon" /></a>
      </div>
      <p className='tablet:text-[15px] minMobil:text-xl leading-6 minMobil:w-full tablet:max-w-max minMobil:hidden tablet:block font-mono'>©2024. Barcha huquqlar himoyalangan.</p>

    </div>
  )
}

export default Left