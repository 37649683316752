import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AVATARIMG } from '../../../../img/img'
import { ToastContainer, toast } from 'react-toastify'
import ApiRequest from '../../../../configure/ApiRequestor/ApiRequest'

function Aloqa() {
  const [contacts, SetContacts] = useState([])
  useEffect(() => {
    getContacts()
  }, [])
  function getContacts() {
    ApiRequest("/contact","get").then((res) => {
      SetContacts(res.data);
    })
  }

  function DeleteItem(item) {
    ApiRequest("/contact/" + item.id,"delete").then((res) => {
      getContacts()
    }).catch((error) => {
      toast.error(error)
    })
  }

  return (
    <div className='py-4'>
      <h1 className='text-4xl font-mono text-center'>Aloqa</h1>
      <div className='w-full h-[100%] overflow-auto py-2 flex items-center justify-center flex-wrap gap-5'>
        {
          contacts.length == 0 && <h1 className='text-white'>Hech qanday ma'lumot yo'q</h1> || contacts.map((item, i) => {
            return <div key={i} className='w-[250px] h-[350px]  flex flex-col justify-center border p-1 rounded-xl card-border-radius-2  '>
              <div className='w-10 h-10 rounded-full bg-white text-textBlack flex items-center justify-center'>{i + 1}</div>
              <div className='w-full h-[60%]'>
                <img src={AVATARIMG} className='w-full h-full' alt="AVATARIMG" />
              </div>
              <div className='flex flex-col gap-2 items-center justify-center'>
                <div className='w-full h-[40%] bg-green'>
                  <h1 className='w-full h-full px-2 text-white font-bold text-sm '><span className='font-mono text-lg'>Name:</span> {item?.name}</h1>
                </div>
                <div className='w-full h-[40%] bg-blue-500'>
                  <h1 className='w-full h-full px-2 text-white font-bold text-sm'><span className='font-mono text-lg'>Adress:</span> {item?.address}</h1>
                </div>
                <div className='w-full h-[40%] bg-green'>
                  <h1 className='w-full h-full px-2 text-white font-bold text-sm'><span className='font-mono text-lg'>Age:</span> {item?.age}</h1>
                </div>
                <div className='w-full h-[40%] bg-blue-500'>
                  <h1 className='w-full h-full px-2 text-white font-bold text-sm'><span className='font-mono text-lg'>Phone:</span> {item?.phone}</h1>
                </div>
              </div>
              <button onClick={() => (DeleteItem(item))} className='btn btn-danger relative -bottom-5'>Delete</button>
            </div>
          })
        }
        <ToastContainer />

      </div>
    </div>
  )
}

export default Aloqa