import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "./page/components/home";
import { NoteFoud } from "./page/404/index";
import { AboutJS } from "./page/About";
import { ADMIN } from "./page/admin";
import { Kurslar } from "./page/courses";
import { CALLME } from "./page/contact/collme";
import { GALERYPAGE } from "./page/galery";
import { HAPPYCAMP } from "./page/camp/campExport";
import Login from "./page/login/Login";

function App() {
  const token = localStorage.getItem("token");
  return (
    <Routes >
      {/* **************ADMIN PAGES**************** */}
      <Route path="/" element={<Home />} />
      <Route path="/webadmin/" element={<Login />} />
      <Route path={`/webadmin/${token}`} element={<ADMIN />} />
      {/* *************************************************************** */}
      <Route path="/about" element={<AboutJS />} />
      <Route path="/courses" element={<Kurslar />} />
      <Route path="/camp" element={<HAPPYCAMP />} />
      <Route path="/gallery" element={<GALERYPAGE />} />
      <Route path="/contact" element={<CALLME />} />
      {/* **************************************************************** */}
      <Route path="*" element={<NoteFoud />} />
    </Routes>
  );
}

export default App;
