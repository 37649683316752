import { useDispatch, useSelector } from "react-redux";
import { HamburgerMenu, LunaIcon, SUNIcon, darkMoodIcon, ligthMoodIcon } from "../../../icons/icons.";
import { trueVisibility } from "../../redux/sidebar";
import { trueVisibilityMood } from "../../redux/sun";
import { Link, useLocation } from "react-router-dom";
import { navbar } from "../../export";

function Header() {
  const Moodlight = useSelector((state) => state.sunBTN)
  const dispatch = useDispatch()
  const location = useLocation()
  const locale = location.pathname


  return (
    <div className={`flex items-center justify-center overflow-hidden laptop: max-w-[1340px] minMobil: w-full h-full ${!Moodlight.SunVisibility ? "bg-textBlack" : "bg-white"} `}>
      <div className="flex items-center justify-between w-full py-3 px-2  ">
        <a href="/" className="w-40">
          <img src={!Moodlight.SunVisibility ? ligthMoodIcon : darkMoodIcon} alt="logoIcon" className="w-full h-full" />
        </a>
        <ul className="minMobil:hidden tablet:flex items-center justify-center gap-5 ">
          {
            navbar.map((item, len) => {
              return <li key={len + 1} className={`${!Moodlight.SunVisibility ? "text-white " : "text-textBlack"} ${item.link === locale ? 'greenTextMe' : ''} text-center hover:text-green duration-500`}>
                <Link to={item.link}>{item.title}</Link>
              </li>
            })
          }
        </ul>
        <div className="flex items-center justify-center gap-3">
          <button onClick={() => (dispatch(trueVisibilityMood()))}><img src={!Moodlight.SunVisibility ? SUNIcon : LunaIcon} alt="SUNIcon" className={`${!Moodlight.SunVisibility ? 'rotating' : ''} w-8`} /></button>
          <div className='minMobil:hidden tablet:flex items-center justify-center gap-3'>
            <Link to={'/contact'} className="bg-green py-2 px-3 rounded btn-hover">Ariza yuborish</Link>
          </div>
          <div className="flex items-center justify-center">
            <button onClick={() => (dispatch(trueVisibility()))} className="bg-green p-3 rounded-full   tablet:hidden minMobil:block"><img src={HamburgerMenu} alt="" /></button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Header