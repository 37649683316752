import React from 'react'
import { Navbar } from '../components/Navbar/PageExport'
import { NoteFoundPage } from './index'
import Footer from '../components/Footer/Footer'
import { SideBarJS } from '../utils'
import { useSelector } from 'react-redux'

function PageNoteFound() {
    const Moodlight = useSelector((state) => state.sunBTN)
    return (
        <div className={`flex flex-col justify-between min-h-screen ${!Moodlight.SunVisibility ? "bg-textBlack" : "bg-white"}`}>
            <SideBarJS />
            <Navbar />
            <NoteFoundPage />
            <div className='w-full bg-textBlack flex justify-center borderWi '>
                <Footer />
            </div>
        </div>
    )
}

export default PageNoteFound