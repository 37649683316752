import React, { useEffect, useState } from 'react'
import { COURSEIMG, IMGADMINPAGE } from '../../../../img/img'
import { ToastContainer, toast } from 'react-toastify';
import ApiRequest, { BASE_URL } from '../../../../configure/ApiRequestor/ApiRequest';
function BizningKurslar() {
  const [imgId, setImgId] = useState('')
  const [IMGFRONT, setIMGFRONT] = useState('')
  const [CourseDuration, setCourseDuration] = useState('')
  const [discription, setDiscription] = useState('')
  const [Title, setTitle] = useState('')
  const [CoursesData, setCoursesData] = useState([])
  const [edit, setEdit] = useState('')
  useEffect(() => {
    getCoursesData()
  }, [])
  function getCoursesData() {
    ApiRequest("/course", "get").then((res) => {
      setCoursesData(res.data)
    })
  }
  function Change(e) {
    let formData = new FormData
    formData.append("file", e.target.files[0])
    formData.append("prefix", "/images/courseImages")
    ApiRequest("/file", "post", formData).then((res) => {
      setImgId(res.data)
      toast.info(res.data)
    }).catch((err) => { toast.error(err) })

    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = () => {
      setIMGFRONT(reader.result)
    }
    reader.onerror = (err) => {
      console.log(err);
    }
  }
  function SubmitArr() {
    // *************************************** if bo'shlikka tekshirilgan ********************************
    if (CourseDuration !== '' && discription !== '' && Title !== '') {
      // ************************************ Post'ga tekshirilgan post method ************************************
      let obj = {
        name: Title,
        duration: CourseDuration,
        imageId: imgId,
        description: discription,
      }
      if (edit === '') {
        ApiRequest("/course", "post", obj).then((res) => {
          toast.success(res.data, "Success");
          getCoursesData()
          setImgId('')
          setTitle('')
          setCourseDuration('')
          setDiscription('')
          setEdit('')
        }).catch((err) => { toast.error(err) });

        // ******************************* Edit'ga tekshirilgan Edit *********************************************
      } else {
        ApiRequest("/course/" + edit, "put", obj).then((res) => {
          toast.success(res.data, "Success Edit");
          getCoursesData()
          setImgId('')
          setTitle('')
          setCourseDuration('')
          setDiscription('')
          setEdit('')
        })
      }
      // *********************************** Else Bo'shlikka tekshirilgan *********************************************
    } else {
      toast.info("Iltimos Hamma qatorlarni to'ldiring")
    }
  }
  function DeleteItem(id) {
    ApiRequest("/course/" + id, "delete").then((res) => {
      toast.success("success delete info")
      getCoursesData()
    }).catch((error) => { toast.error(error) })
  }
  function EditInfo(getPosts) {
    setImgId(getPosts.imageId)
    setCourseDuration(getPosts.duration)
    setDiscription(getPosts.description)
    setTitle(getPosts.name)
    setEdit(getPosts.id)
  }
  return (
    <div className='py-4'>
      <div className=''>
        <h1 className='text-4xl font-mono text-center'>Bizning kurslar</h1>
        <div className='flex flex-col items-start justify-center gap-3'>
            <input required onChange={(e) => Change(e)} name='file_update' type="file" />
          <input
            value={Title}
            onChange={(e) => (setTitle(e.target.value))}
            required
            className='form-control w-50 form-control placeholder:uppercase placeholder:font-mono'
            type="text" placeholder='Kurs Nomi kamida 6ta belgi' />

          <input
            value={CourseDuration}
            onChange={(e) => (setCourseDuration(e.target.value))}
            required
            className='form-control w-50 form-control placeholder:uppercase placeholder:font-mono'
            type="text"
            placeholder='kurs davomiyligi raqamlarda {expamle: 3} + oy' />

          <textarea
            value={discription}
            onChange={(e) => (setDiscription(e.target.value))}
            required
            className='form-control w-50 h-40 form-control placeholder:uppercase placeholder:font-mono '
            placeholder='Kurs haqida batafsil izoh qoldiring kamida 100ta belgi'>
          </textarea>

          <button onClick={() => (SubmitArr())} className='btn btn-success'>Yakunlash</button>
        </div>
      </div>
      <div className='w-full h-96 overflow-auto my-3'>
        <table className='table '>
          <thead className='table-dark'>
            <tr>
              <th>id</th>
              <th>img</th>
              <th>Title</th>
              <th>Duration</th>
              <th>discription</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody className='table-hover'>
            {
              CoursesData.map((i, id) => {

                return <tr key={id}>
                  <td>{id + 1}</td>
                  <td><img src={i.imageId !== null ? BASE_URL + "api/file/" + i.imageId : COURSEIMG} alt="" width={100} /></td>
                  <td>{i?.name}</td>
                  <td>{i?.duration}</td>
                  <td><p className='w-[400px] bg-red-400'>{i.description}</p></td>
                  <td>
                    <button onClick={() => (DeleteItem(i.id))} className='btn btn-danger'>Delete</button>
                    <button onClick={() => (EditInfo(i))} className='btn btn-warning'>Edit</button>
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  )
}

export default BizningKurslar