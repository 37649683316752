import { configureStore } from '@reduxjs/toolkit'
import sidebarbtn from './sidebar'
import answer from './answer'
import sunBTN from './sun'
import AdminReduse from './adminPage'



export const store = configureStore({
  reducer: {
    sidebarbtn,
    answer,
    sunBTN,
    AdminReduse
  },
})