import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settingsGalleryCourses } from "../../export";
import { leftStrelka } from "../../../icons/icons."
import CardCourses from "../../utils/CardCourses";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ApiRequest from "../../../configure/ApiRequestor/ApiRequest";
import { COURSEIMG } from "../../../img/img";
import { Link } from "react-router-dom";



function OurCourses() {
    const Moodlight = useSelector((state) => state.sunBTN)
    const [coursesData, setCoursesData] = useState([])
    useEffect(() => {
        getCoursesData()
    }, [])
    function getCoursesData() {
        ApiRequest("/course", "get").then((res) => {
            setCoursesData(res.data)
        })
    }



    return (
        <div className=" overflow-hidden tablet:w-[1340px] minMobil:w-full flex flex-col gap-4 items-center justify-between tablet:mt-10 minMobil:mt-4">
            <div className='flex items-center justify-between tablet:px-3 minMobil:px-1  w-[100%]'>
                <div className='flex items-center justify-center gap-3'>
                    <div className='w-14 h-1 bg-green minMobil:hidden tablet:block'></div>
                    <h1 className={`text-textBlack tablet:text-4xl minMobil:text-2xl leading-2 font-bold uppercase ${!Moodlight.SunVisibility ? "text-white" : " text-textBlack"}`}>Bizning <span className="text-green">kurslar</span></h1>
                </div>
                <Link to="/courses" className='flex items-center justify-between gap-2 tablet:text-2xl minMobil:xl text-green font-semibold'><span className="minMobil:hidden tablet:block uppercase">Barchasi Kurslar </span><span className="minMobil:block tablet:hidden uppercase">Barchasi</span><img src={leftStrelka} alt="leftStrelka" /></Link>
            </div>
            <div className=" tabletIst:w-[100%] laptop:w-[100%] minMobil:w-full">
                <div className="slider-container max-w-[1340px] bg-center">
                    <Slider {...settingsGalleryCourses} >

                        {
                            coursesData.map((item, index) => {
                                return <CardCourses foto1={item.imageId !== null ? item.imageId:COURSEIMG} duration={item.duration} title={item.name} key={index} />
                            })

                        }

                    </Slider>

                </div>
            </div>

        </div>
    )
}

export default OurCourses