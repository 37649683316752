import React, { useEffect, useState } from 'react'
import { leftStrelka } from '../../../icons/icons.'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ApiRequest from '../../../configure/ApiRequestor/ApiRequest'

function FaceAboutMe() {
    const Moodlight = useSelector((state) => state.sunBTN)
    const [getPosts, setGetPosts] = useState([])
    const location = useLocation()
    useEffect(() => {
        ApiRequest("/aboutUs","get").then((res) => {
            setGetPosts(res.data)
        })
    }, [])


    return (
        <div className='max-w-[1340px] flex flex-col gap-4 items-stretch justify-center tablet:mt-10 minMobil:mt-4'>
            <div className='flex items-center justify-between px-3 '>
                <div className='flex items-center justify-center gap-3'>
                    <div className='w-14 h-1 bg-green tablet:block minMobil:hidden'></div>
                    <h1 className={`text-textBlack tablet:text-4xl minMobil:text-2xl font-bold uppercase ${!Moodlight.SunVisibility ? "text-white" : " text-textBlack"}`}>Biz haqimizda</h1>
                </div>
                <a href='/about' className={`flex ${location.pathname === '/about' ? "hidden" : "block"} items-center justify-between gap-2 tablet:text-2xl minMobil:xl text-green font-semibold uppercase`}>Batafsil <img src={leftStrelka} alt="leftStrelka" /></a>
            </div>
            {
                getPosts.map((item) => {
                    return <div key={item.id} className='flex items-center laptop:justify-between minMobil:justify-center minMobil:flex-col tabletIst:flex-row gap-6 tablet:px-3 minMobil:px-1'>
                        <p className={`laptop:max-w-[50%] minMobil:w-full minMobil:text-[18px] minMobil:hidden tablet:block  tablet:text-[22px] ${!Moodlight.SunVisibility ? "text-white" : " text-textBlack"}  minMobil:text-center tabletIst:text-start`}>
                            {item.textTablet}
                        </p>
                        <p className={`w-full text-[18px] minMobil:block tablet:hidden ${!Moodlight.SunVisibility ? "text-white" : " text-textBlack"}  minMobil:text-center tabletIst:text-start`}>
                            {item.textMobile}
                        </p>

                        <div className='minMobil:w-full tablet:w-1/2 flex items-center justify-end'>
                            <iframe className='rounded-2xl tablet:w-[560px] minMobil:h-[350px]  minMobil:w-[100%] ' src={item.youtubeUrl}></iframe>
                        </div>
                    </div>


                })
            }

        </div>
    )
}

export default FaceAboutMe