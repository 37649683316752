import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../../configure/ApiRequestor/ApiRequest'

function CardCourses({ foto1, title, duration }) {
    const Moodlight = useSelector((state) => state.sunBTN)
    return (
        <div className={`minMobil:w-[300px]  tablet:w-[290px] minMobil:h-[400px] overflow-hidden  ${!Moodlight.SunVisibility ? "card-border-radius-3" : "card-border-radius-2"} scaleCard `}>
            <div className='w-full h-[220px] flex items-center justify-center rounded-xl'>
                <img className='w-full h-full bg-cover card-border-radius' src={BASE_URL + "api/file/" + foto1} alt="img" />
            </div>
            <div className='flex items-start justify-between  h-[42%] p-3 flex-col'>
                <div className='flex items-start justify-center flex-col gap-2'>
                    <h1 className={`font-bold tablet:text-2xl minMobil:xl ${!Moodlight.SunVisibility ? "text-white" : " text-textBlack"}`}>{title}</h1>
                    <p className='text-green leading-8 minMobil:text-sm tablet:text-[17px]'>Darslar davomiyligi: {duration} oy </p>
                </div>
                <Link to={'/contact'} className='py-2 px-4 rounded-md  btn-hover'>Ariza yuborish</Link>
            </div>
        </div>
    )
}

export default CardCourses