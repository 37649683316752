import { leftStrelka } from "../../../icons/icons."
import { CardGalery } from "../../utils"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settingsGallery } from "../../export";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ApiRequest, { BASE_URL } from "../../../configure/ApiRequestor/ApiRequest";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";


function Galery() {
    const Moodlight = useSelector((state) => state.sunBTN)
    const [data, setData] = useState([])

    useEffect(() => {
        getPost()
    }, [])
    function getPost() {
        ApiRequest("/photoGallery", "get")
            .then((res) => {
                setData(res.data)
            }).catch((err) => { toast.error(err, "Galery Serverda xatolik") });
    }
    return (
        <div className=" overflow-hidden w-[1340px] flex flex-col gap-4 items-center justify-between tablet:mt-10 minMobil:mt-4">
            <div className='flex items-center justify-between tablet:px-3 minMobil:px-1  w-[100%]'>
                <div className='flex items-center justify-center gap-3'>
                    <div className='w-14 h-1 bg-green minMobil:hidden tablet:block'></div>
                    <h1 className={` tablet:text-4xl minMobil:text-2xl leading-2 font-bold uppercase ${!Moodlight.SunVisibility ? "text-white" : " text-textBlack"}`}>Fotogaleriya</h1>
                </div>
                <Link to="/gallery" className='flex items-center justify-between gap-2 tablet:text-2xl minMobil:xl text-green font-semibold'><span className="minMobil:hidden tablet:block uppercase">Barcha Rasmlar </span><span className="minMobil:block tablet:hidden uppercase text-[18px]">Barchasi</span><img src={leftStrelka} alt="leftStrelka" /></Link>
            </div>
            <div className="slider-container tabletIst:w-[100%] laptop:w-[100%] minMobil:w-full">
                <div>
                    <Slider {...settingsGallery} >
                        {
                            data.map((d, id) => (
                                <CardGalery key={d.id} id={id} foto1={BASE_URL + "api/file/" + d?.photoId} />

                            ))
                        }

                    </Slider>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Galery