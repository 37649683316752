import { createSlice } from "@reduxjs/toolkit";

export const visibilityAnsver = createSlice({
    name: 'AnsverBtn',
    initialState: {
        visibilityAnsver: false
    },
    reducers: {
        trueVisibilityAnsver: (state) => {
            if (state.visibilityAnsver === true) {
                state.visibilityAnsver = false
            }
            else {
                state.visibilityAnsver = true
            }
        }
    }
})

export const { trueVisibilityAnsver } = visibilityAnsver.actions

export default visibilityAnsver.reducer