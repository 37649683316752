import React from 'react'
import { footerWorkGraph } from '../../export'

function Center() {
    return (
        <div className='max-w-full flex flex-col gap-2 tablet:mt-0 minMobil:mt-5'>
            {
                footerWorkGraph.map((item, i) => {
                    return <div key={i} className='flex gap-3 items-center align-bottom w-max'>
                        <img className='w-7 h-7' src={item.img} alt="img" />
                        <div>
                            <h1>{item.title}</h1>
                            <p className='max-w-[300px]'>{item.text}</p>
                        </div>
                    </div>
                })
            }
            <p className=' text-lg w-full  minMobil:block tablet:hidden font-mono mt-10'>©2024. Barcha huquqlar himoyalangan.</p>
        </div>
    )
}

export default Center