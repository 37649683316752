import React from 'react'
import CardWhyUse from '../../utils/CardWhyUse'
import { useSelector } from 'react-redux'

function WhyUs() {
    const Moodlight = useSelector((state) => state.sunBTN)
    return (
        <div className='w-[1340px] min-h-10  tablet:px-3 minMobil:px-1'>
            <div className='w-full flex items-center justify-between'>
                <div className='flex items-center justify-center gap-3'>
                    <div className='w-14 h-1 bg-green minMobil:hidden tablet:block'></div>
                    <h1 className={`tablet:text-4xl minMobil:text-2xl leading-2 font-bold uppercase ${!Moodlight.SunVisibility ? "text-white" : " text-textBlack"}`}>nega aynan <span className='text-green'> biz?</span></h1>
                </div>
            </div>
            <div className='flex flex-col gap-7'>
                <CardWhyUse />
            </div>
        </div>
    )
}

export default WhyUs