import React, { useEffect, useState } from 'react'
import { Navbar } from '../components/Navbar/PageExport'
import { Footers } from '../components/Footer/index'
import { NewCardCoursePage, SideBarJS } from '../utils/index'
import { useSelector } from 'react-redux'
import { COURSEIMG } from '../../img/img'
import { translateRu } from '../../icons/icons.'
import ApiRequest, { BASE_URL } from '../../configure/ApiRequestor/ApiRequest'


function Kurslar() {
  const Moodlight = useSelector((state) => state.sunBTN)
  const [coursesData, setCoursesData] = useState([])

  useEffect(() => {
    getCoursesData()
  }, [])

  function getCoursesData() {
    ApiRequest("/course", "get").then((res) => {
      setCoursesData(res.data)
    })
  }
  return (
    <div className={`flex flex-col justify-between min-h-screen ${!Moodlight.SunVisibility ? "bg-textBlack" : "bg-white"}`}>
      <SideBarJS />
      <Navbar />
      <div className='flex items-center justify-center my-5'>
        <div className='w-[1340px] px-1'>
          <div className='flex items-center justify-start gap-3'>
            <div className='w-14 h-1 bg-green minMobil:hidden tablet:block'></div>
            <h1 className={`text-textBlack tablet:text-4xl minMobil:text-2xl leading-2 font-bold uppercase minMobil:text-center tablet:text-start w-full ${!Moodlight.SunVisibility ? "text-white" : " text-textBlack"}`}>Bizning <span className="text-green">kurslar</span></h1>
          </div>
          <h1 className={`uppercase tablet:text-3xl minMobil:text-2xl text-center font-bold my-5 ${!Moodlight.SunVisibility ? "text-white" : " text-textBlack"} `}><span className='text-green'>"New Star School" </span>da Farzandingiz uchun kurslar ro'yxati:</h1>
          <div className='flex flex-wrap gap-5 items-center justify-center'>
            {coursesData.map((item, index) => {
              console.log(item.imageId);
              return (
                <NewCardCoursePage key={index} img={`${BASE_URL + 'api/file/' + item.imageId}`} alt={item.name} width={100} icon={translateRu} url={'/contact'} title={item.name !== null ? item?.name : "Bizning Kurslar"} discription={item?.description} />
              )
            })}
          </div>
        </div>
      </div>
      <div className='w-full bg-textBlack flex justify-center borderWi '>
        <Footers />
      </div>
    </div>
  )
}

export default Kurslar