import React from 'react'
import { clocFooter, locationFooter, mailFooter, phoneFooter, } from '../../icons/icons.';
import { useSelector } from 'react-redux';
import { linkMap } from '../export';
function Map() {
    const Moodlight = useSelector((state) => state.sunBTN)

    return (
        <div className='w-full min-h-[400px] flex items-center minMobil:justify-center tablet:justify-between minMobil:flex-wrap laptop:flex-nowrap gap-4'>
            <div className='minMobil:w-full tablet:w-1/2 min-h-[400px]'>
                <iframe className='border w-full min-h-[400px]' src={linkMap} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className='minMobil:w-full tabletIst:w-1/2 min-h-[400px] flex flex-col items-start justify-between py-2'>
                <div className='flex items-center justify-start gap-3 text-textBlack min-h-[50px]  '>
                    <img src={phoneFooter} width={40} height={40} alt="phoneIcon" />
                    <div className='h-[40px]'>
                        <p className={`${!Moodlight.SunVisibility ? "text-green" : "text-textBlack"} font-bold tablet:text-2xl minMobil:text-xl leading-8`}>Telefon raqam:</p>
                        <a className={`${!Moodlight.SunVisibility ? "text-white" : "text-[#00000080]"} font-semibold tablet:text-xl minMobil:text-lg leading-6`} href="tel:+998555007774">+998 55 500 77 74</a>
                    </div>
                </div>
                <div className='flex items-center justify-start gap-3 text-textBlack h-[50px]  '>
                    <img src={locationFooter} width={40} height={40} alt="phoneIcon" className='p-1' />
                    <div className='h-[40px]'>
                        <p className={`${!Moodlight.SunVisibility ? "text-green" : "text-textBlack"} font-bold tablet:text-2xl minMobil:text-xl leading-8`}>Manzil:</p>
                        <a className={`${!Moodlight.SunVisibility ? "text-white" : "text-[#00000080]"} font-semibold tablet:text-xl minMobil:text-lg leading-6`} href="https://maps.app.goo.gl/GFfric5UbCRmoorh8">Buxoro shahar, Xo’ja Alovaddin ko’cha 81-uy</a>
                    </div>
                </div>
                <div className='flex items-center justify-start gap-3 text-textBlack h-[50px]  '>
                    <img src={mailFooter} width={40} height={40} alt="phoneIcon" />
                    <div  className='h-[40px]'>
                        <p className={`${!Moodlight.SunVisibility ? "text-green" : "text-textBlack"} font-bold tablet:text-2xl minMobil:text-xl leading-8`}>Pochta:</p>
                        <a className={`${!Moodlight.SunVisibility ? "text-white" : "text-[#00000080]"} font-semibold tablet:text-xl minMobil:text-lg leading-6`} href="mailto:newstarschoolbukhara@gmail.com">newstarschoolbukhara@gmail.com
                        </a>
                    </div>
                </div>
                <div className='flex items-center justify-start gap-3 text-textBlack h-[50px]  '>
                    <img src={clocFooter} width={40} height={40} alt="phoneIcon" />
                    <div className='h-[40px]'>
                        <p className={`${!Moodlight.SunVisibility ? "text-green" : "text-textBlack"} font-bold tablet:text-2xl minMobil:text-xl leading-8`}>Ish grafigi:</p>
                        <a className={`${!Moodlight.SunVisibility ? "text-white" : "text-[#00000080]"} font-semibold tablet:text-xl minMobil:text-lg leading-6`}>Dushanba-Juma 8:00 dan 18:00 gacha</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Map