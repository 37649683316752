import axios from "axios";
import { ErrorNotify } from "../../page/utils/ErrorNotify/ErrorNotify";
import AxiosInterSeptor from "../AxiosInterceptor/AxiosInterSeptor"
// export const BASE_URL = "http://localhost:8080/";
export const BASE_URL = "http://localhost:8082/";

export default function ApiRequest(url, method, data, params) {

  // let x = localStorage.getItem("access_token")
  return AxiosInterSeptor({
    baseURL: BASE_URL,
    url: "/api" + url,
    method,
    data,
    params,
    //   headers:{
    //     "Authorization": `Bearer ${x}`
    // }
  }).catch((err) => {
    switch (err.response?.status) {
      case 401:
        ErrorNotify(err.response.data);
        break;
      case 404:
        ErrorNotify(err.response.data);
        break;
      case 403:
        ErrorNotify("You don't have permission");
        break;
      case 500:
        ErrorNotify('Serverda xatolik yuz berdi');
        break;
      default:
        ErrorNotify("An error has ocurred")
        // Handle other status codes as needed
        break;
    }
  });
};





