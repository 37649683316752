import React, { useEffect, useState } from 'react'
import { IMGADMINPAGE } from '../../../../img/img'
import { ToastContainer, toast } from 'react-toastify';
import ApiRequest, { BASE_URL } from '../../../../configure/ApiRequestor/ApiRequest';
function NegaBiz() {
  const [imgId, setImgId] = useState('')
  const [Title, setTitle] = useState('')
  const [discription, setDiscription] = useState('')
  const [CoursesData, setCoursesData] = useState([])
  const [edit, setEdit] = useState('')

  useEffect(() => {
    getCoursesData()
  }, [])

  function getCoursesData() {
    ApiRequest("/whyUs", "get").then((res) => {
      setCoursesData(res.data)
    })
  }
  function handlePhoto(e) {
    let formData = new FormData
    formData.append("file", e.target.files[0])
    formData.append("prefix", "/images/whyUsImages")
    ApiRequest("/file", "post", formData).then((res) => {
      setImgId(res.data)
    })
  }
  function SubmitArr() {
    // *************************************** if bo'shlikka tekshirilgan ********************************
    if (discription !== '' && Title !== '') {
      let obj = {
        title: Title,
        imageId: imgId,
        desc: discription,
      }
      // ************************************ Post'ga tekshirilgan post method ************************************
      if (edit === '') {
        ApiRequest("/whyUs", "post", obj).then((res) => {
          toast.success(res.data, "Success");
          getCoursesData()
          setImgId('')
          setTitle('')
          setDiscription('')
          setEdit('')
        }).catch((err) => { toast.error(err) });

        // ******************************* Edit'ga tekshirilgan Edit *********************************************
      } else {
        ApiRequest("/whyUs/" + edit, "put", obj).then((res) => {
          toast.success(res.data, "Success Edit");
          getCoursesData()
          setImgId('')
          setDiscription('')
          setTitle('')
          setEdit('')
        })
      }
      // *********************************** Else Bo'shlikka tekshirilgan *********************************************
    } else {
      toast.info("Iltimos Hamma qatorlarni to'ldiring")
    }

  }

  function DeleteItem(info) {
    ApiRequest("/whyUs/" + info.id, "delete").then(() => {
      toast.success("success delete info")
      getCoursesData()
    }).catch((error) => { toast.error(error) })
  }


  function EditInfo(getPosts) {
    setImgId(getPosts.imageId)
    setDiscription(getPosts.description)
    setTitle(getPosts.title)
    setEdit(getPosts.id)

  }

  return (
    <div className='py-4'>
      <div className=''>
        <h1 className='text-4xl font-mono text-center'>Nega Aynan biz</h1>
        <div className='flex flex-col items-start justify-center gap-3'>
            <input required onChange={(e) => handlePhoto(e)} name='file_update' type="file" />
          <input
            value={Title}
            minLength={6}
            onChange={(e) => (setTitle(e.target.value))}
            required
            className='form-control w-50 form-control placeholder:uppercase placeholder:font-mono'
            type="text" placeholder='Maktab Haqida Sarlavha' />

          <textarea
            value={discription}
            minLength={100}
            onChange={(e) => (setDiscription(e.target.value))}
            required
            className='form-control w-50 form-control h-40 placeholder:uppercase placeholder:font-mono '
            placeholder='Maktab haqida Izoh kamida 100ta belgi'>
          </textarea>

          <button onClick={SubmitArr} className='btn btn-success'>Yakunlash</button>
        </div>
      </div>
      <div className='w-full h-96 overflow-auto my-3'>
        <table className='table '>
          <thead className='table-dark'>
            <tr>
              <th>id</th>
              <th>img</th>
              <th>Title</th>
              <th>discription</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody className='table-hover'>
            {
              CoursesData.map((i, id) => {
                return <tr key={id}>
                  <td>{id + 1}</td>
                  <td><img src={BASE_URL + "api/file/" + i.imageId} alt="img" width={100} /></td>
                  <td>{i?.title}</td>
                  <td><p className='w-[400px] bg-red-400'>{i.description}</p></td>
                  <td>
                    <button onClick={() => (DeleteItem(i))} className='btn btn-danger'>Delete</button>
                    <button onClick={() => (EditInfo(i))} className='btn btn-warning'>Edit</button>
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>

      <ToastContainer />
    </div>
  )
}

export default NegaBiz