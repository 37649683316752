import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import ApiRequest from "../../../../configure/ApiRequestor/ApiRequest";
function Savollar() {
  const [savol, setSavol] = useState("");
  const [javob, setJavob] = useState("");
  const [post, setPost] = useState([]);
  const [edit, setEdit] = useState("");
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    getAnswerPosts();
  }, []);


  function getAnswerPosts() {
    ApiRequest("/landQuestion", "get").then((res) => {
      setPost(res.data);
    });
  }

  function SaveAnsverQuestion() {
    let obj = {
      title: savol,
      description: javob,
      isActive: checked,
    };
    console.log(checked);
    if (edit === "") {
      if (
        savol !== "" &&
        javob !== "" &&
        savol.length > 1 &&
        javob.length > 1
      ) {
        ApiRequest("/landQuestion", "post", obj)
          .then((res) => {
            toast.success("Successfully saved");
            setSavol("");
            setJavob("");
            setEdit("");
            getAnswerPosts();
          })
          .catch((err) => {
            toast.error("Error saved");
          });
      } else {
        toast.error("Iltimos barcha qatorlarni to'ldiring");
      }
    } else {
      ApiRequest("/landQuestion/" + edit, "put", obj)
        .then((res) => {
          toast.success("Successfully saved");
          setSavol("");
          setJavob("");
          setEdit("");
          getAnswerPosts();
        })
        .catch((err) => {
          toast.error("Error saved");
        });
    }
  }

  function DeleteItem(i) {
    ApiRequest("/landQuestion/" + i.id, "delete")
      .then((res) => {
        toast.success("success delete info");
        getAnswerPosts();
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  function EditItem(i) {
    setSavol(i.title);
    setJavob(i.description);
    setEdit(i.id);
    getAnswerPosts();
  }

  return (
    <div className="flex flex-col gap-5 py-4 w-full">
      <h1 className="text-4xl font-mono text-center">Savollar</h1>
      <div className="flex flex-col gap-4 items-start justify-center">
        <input
          value={savol}
          minLength={6}
          onChange={(e) => setSavol(e.target.value)}
          required
          className="form-control w-50 form-control placeholder:uppercase placeholder:font-mono"
          type="text"
          placeholder="Maktab Haqida Sarlavha"
        />

        <textarea
          value={javob}
          minLength={100}
          onChange={(e) => setJavob(e.target.value)}
          required
          className="form-control w-50 form-control h-40 placeholder:uppercase placeholder:font-mono "
          placeholder="Maktab haqida Izoh kamida 100ta belgi"
        ></textarea>
        <input type="checkbox" onChange={(e) => setChecked(e.target.checked)} />
        <button
          onClick={() => SaveAnsverQuestion()}
          className="btn btn-success"
        >
          Saqlash
        </button>
      </div>

      <table className="table">
        <thead className="table-dark">
          <tr>
            <td>id</td>
            <td>Savol</td>
            <td>Javob</td>
            <td>Active</td>
            <td>Delete</td>
            <td>Edit</td>
          </tr>
        </thead>
        <tbody className="table-hover">
          {post.map((i, id) => {
            return (
              <tr key={id}>
                <td>{id + 1}</td>
                <td className="w-96">{i?.title}</td>
                <td className="w-96">{i?.description}</td>
                <td className="w-96">{i?.isActive ? "True" : "False"}</td>
                <td>
                  <button
                    onClick={() => DeleteItem(i)}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => EditItem(i)}
                    className="btn btn-warning"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <ToastContainer />
    </div>
  );
}

export default Savollar;
