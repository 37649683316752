import React, { useEffect, useState } from 'react'
import { exitIcon, logoIcon } from '../../icons/icons.'
import { useNavigate } from 'react-router-dom'
import { CRUDPAGE } from '.'
import { useDispatch, useSelector } from 'react-redux'
import { AdminCRUDPageChange } from '../redux/adminPage'

function Admin() {
  const AdminSlise = useSelector((state) => state.AdminReduse.AdminPath)
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 4.5 * 1000)
  }, [loader])
  return (
    <div>
      {
        loader ?
          <div className={`w-full min-h-screen flex items-center justify-center bg-textBlack`}>
            <span className="loader w-[300px] "></span>
          </div>
          :
          <div className='w-full bg-textBlack h-screen flex'>
            <div className='w-1/4 h-screen  flex flex-col items-center justify-center gap-5 border-r border-[#6f6e6e]'>
              <img src={logoIcon} width={130} alt="logoIcon" />
              <div className='w-full flex flex-col items-center justify-center gap-6'>
                <button onClick={() => (dispatch(AdminCRUDPageChange('about-us')))} className={`btn ${AdminSlise === 'about-us' ? 'btn-warning' : 'btn-outline-warning'}  w-3/4 text-white  `}>BIZ HAQIMIZDA</button>
                <button onClick={() => (dispatch(AdminCRUDPageChange('our-courses')))} className={`btn ${AdminSlise === 'our-courses' ? 'btn-warning' : 'btn-outline-warning'} w-3/4 text-white `}>BIZNING KURSLAR</button>
                <button onClick={() => (dispatch(AdminCRUDPageChange('why-us')))} className={`btn ${AdminSlise === 'why-us' ? 'btn-warning' : 'btn-outline-warning'} w-3/4 text-white `}>NEGA AYNAN BIZ</button>
                <button onClick={() => (dispatch(AdminCRUDPageChange('fotogalery')))} className={`btn ${AdminSlise === 'fotogalery' ? 'btn-warning' : 'btn-outline-warning'} w-3/4 text-white `}>FOTOGALERIYA && OROMGOH</button>
                <button onClick={() => (dispatch(AdminCRUDPageChange('contact')))} className={`btn ${AdminSlise === 'contact' ? 'btn-warning' : 'btn-outline-warning'} w-3/4 text-white `}>Aloqa</button>
                <button onClick={() => (dispatch(AdminCRUDPageChange('questions')))} className={`btn ${AdminSlise === 'questions' ? 'btn-warning' : 'btn-outline-warning'} w-3/4 text-white `}>Savollar</button>
              </div>
            </div>
            {/* ***************************************** 'left' 'navbar' 'mine bar' 'footer' ****************************** */}
            <div className='w-3/4'>
              <div className=' w-full h-1/6 bg-green border-b border-[#6f6e6e] px-4 flex items-center justify-end'>
                <button onClick={() => (localStorage.setItem('token', ""), navigate('/'))} className='w-10'><img src={exitIcon} alt="exitIcon" /></button>
              </div>
              <div className=' h-4/5 px-4 overflow-auto' >
                <CRUDPAGE />
              </div>
              <div className='w-full h-6 px-4 border-t border-[#6f6e6e] flex items-start justify-center'>
                <p className="w-full font-mono leading-5 text-white text-start">©2024. Barcha huquqlar himoyalangan.</p>
              </div>
            </div>
          </div>
      }
    </div >
  )
}

export default Admin