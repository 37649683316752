
import React from 'react';

function CardGalery({ foto1 }) {
    
    return (
        <div className='w-full my-4 '>
          <div className='flex'>
            <img src={foto1} className='' alt="foto1" />
          </div>
        </div>
    )
}

export default CardGalery