import { useSelector } from "react-redux";
import { Header, TopBar } from "./PageExport";


export default function Navbar() {
  const Moodlight = useSelector((state) => state.sunBTN)
  return (
    <div className="border-b border-[#858282]">
      <TopBar />
      <div className={`flex flex-col items-center justify-center ${!Moodlight.SunVisibility ? "bg-textBlack" : "bg-white"}`}>
        <Header />
      </div>
    </div>
  )
}
