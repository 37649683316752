import Cump from '../cump/Cump'
import Galery from './Galery'

function Fotogalereya() {
  return (
    <div>
      <div className='w-full h-[450px] bg-red-600 overflow-auto px-3'>
        <h1 className='text-4xl font-mono text-center'>Fotogalereya</h1>
        <Galery />
      </div>
      <div className='w-full min-h-[450px] bg-yellow-600 overflow-auto px-3'>
        <h1 className='text-4xl font-mono text-center'>Cump</h1>
        <Cump />
      </div>
    </div>
  )
}

export default Fotogalereya