import React, { useEffect, useState } from 'react'
import { Modal } from 'antd';
import ApiRequest, { BASE_URL } from '../../../configure/ApiRequestor/ApiRequest';
import { ToastContainer, toast } from 'react-toastify';
function Happy() {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState([])
  const [img, setImage] = useState(null)
  useEffect(() => {
    getPost()
  }, [])
  function getPost() {
    ApiRequest("/photoGallery", "get")
      .then((res) => {
        setData(res.data);
      }).catch((err) => { toast.error(err, "Galery Serverda xatolik") });
  }

  return (
    <div className='w-full min-h-max flex items-center justify-between gap-4 flex-wrap'>
      {
        data.map(((i, id) => {
          return (
            <div key={i.id} className='rounded-3xl minMobil:w-full tablet:max-w-[380px] bg-red-500'>
              <img className='rounded-3xl w-full min-h-[288px]'
                onClick={() => (
                  setVisible(true),
                  setImage(BASE_URL + "api/file/" + i?.photoId)
                )}
                src={BASE_URL + "api/file/" + i?.photoId}
              />
              <Modal Modal
                centered
                open={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={850}
                footer={false}
              >
                <div className='flex minMobil:flex-col tablet:flex-row gap-4 min-h-[400px] items-center justify-stretch'>
                  <img className='minMobil:w-full tablet:w-1/2 h-[400px]' src={img} alt="IMG" />
                  <p className='minMobil:w-full tablet:w-1/2 h-[100%] text-xl font-extralight'>{i?.description}</p>
                </div>
              </Modal>
            </div>
          )
        }))



      }

      <ToastContainer />
    </div>
  )
}

export default Happy