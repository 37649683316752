import { clocFooter, locationFooter, mailFooter, phoneFooter } from "../icons/icons.";

export const linkMap = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d483.6176569382638!2d64.43152141151802!3d39.77459006636523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f50074222fd0785%3A0xc93eb71eeac3fbb4!2sNSB_corporation!5e1!3m2!1sru!2s!4v1720290994401!5m2!1sru!2s'

export const login = {
    email: 'NSS1234',
    password: '1234NSS',
    token: '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
}

export const navbar = [
    {
        "id": 1,
        "title": "Biz haqimizda",
        "link": "/about",
        "visible": true
    },
    {
        "id": 2,
        "title": "Kurslar",
        "link": "/courses",
        "visible": false
    },
    {
        "id": 3,
        "title": "Oromgoh",
        "link": "/camp",
        "visible": false
    },
    {
        "id": 4,
        "title": "Fotogalereya",
        "link": "/gallery",
        "visible": false
    },
    {
        "id": 5,
        "title": "Bog’lanish",
        "link": "/contact",
        "visible": false
    }
]

export const settingsBTNGalery = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1340,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,

            }
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,

            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
export const settingsGallery = {
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    rows: 2,
    slidesPerRow: 1,
    dots: true,
    infinite: true,
    slidesToShow: 4,
    initialSlide: 1,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 1000,
    cssEase: "linear",

    responsive: [
        {
            breakpoint: 1340,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,

            }
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,

            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


export const settingsGalleryCourses = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
        {
            breakpoint: 1340,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,

            }
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,

            }
        },
        {
            breakpoint: 750,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },

        {
            breakpoint: 650,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 400,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 325,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};



export const footerWorkGraph = [
    {
        img: phoneFooter,
        title: "Telefon raqam:",
        text: "+998 55 500 77 74",
        type: "tel"
    },
    {
        img: locationFooter,
        title: "Manzil:",
        text: "Buxoro shahar, Xo’ja Alovaddin ko’chasi 81-uy",
        type: "text"
    },
    {
        img: mailFooter,
        title: "Pochta:",
        text: "new-star-school@gmail.com",
        type: "mail"
    },
    {
        img: clocFooter,
        title: "Ish grafigi:",
        text: "Dushanba-Shanba 8:00 dan 18:00 gacha",
        type: "text"
    }
]