import React, { useState } from 'react'
import { cancel, globusIcon } from '../../icons/icons.'
import { useSelector, useDispatch } from 'react-redux'
import { trueVisibility } from '../redux/sidebar'
import { useLocation } from 'react-router-dom'
import { navbar } from '../export'
function Sidebar() {
    const visiblity = useSelector((selector) => selector.sidebarbtn)
    const dispatch = useDispatch()
    const locale = useLocation()

    return (

        <div className={`${visiblity.visibility ? 'block scale-up-hor-right' : 'hidden'} w-[100%] h-[100vh]  bg-textBlack fixed tablet:hidden z-[999] p-5`}>
            <div className='flex items-center justify-end '>
                <button onClick={() => dispatch(trueVisibility())} className='rounded-full w-[35px] h-[35px] bg-green flex items-center justify-center'><img src={cancel} className='rounded-full w-[20px] h-[20px]' alt="cancel" /></button>
            </div>
            <div className='w-full flex items-center justify-center flex-col gap-4'>
                <ul className="minMobil:flex tablet:hidden items-center flex-col justify-center gap-4 ">
                    {
                        navbar.map((item) => {
                            return <li key={item.id} className={`text-white color-border-1 ${item.link === locale.pathname ? 'bg-green' : ''} hover:bg-[#032e52] w-36 h-10 active:border-white active:text-white rounded-lg text-center  duration-450 leading-8 flex items-center justify-center`}>
                                <a href={item.link}>{item.title}</a>
                            </li>
                        })
                    }
                </ul>
                <div className='flex items-center justify-center gap-3'>
                    <button className="bg-green text-white py-2 px-3 rounded btn-hover">Ariza yuborish</button>
                </div>
            </div>

        </div >
    )
}

export default Sidebar